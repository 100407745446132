<template>
  <v-container>
    <div
      height="300px"
      class="  header row ma-0 fill-height text-center align-center justify-center"
    >
      <div class="col col-12">
        <h1 class="text-h4 font-weight-500 mb-2 text-center white--text">
          .
        </h1>
        <div>
          <div class="v-responsive__content">
            <br />
          </div>
        </div>
        <br />
      </div>
    </div>
    <br />
    <v-row class="mt-5" justify="center">
      <span class="display-1 font-weight-light"
        >Frequently asked questions</span
      >
    </v-row>

    <v-row justify="center" class="mt-5">
      <v-col cols="12" xs="12" sm="10">
        <div>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title">
                  What is business language, and how to learn it?</span
                >
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                A business language is a more advanced language proficiency
                combined with the terminology specific to that industry. Basic
                skills may be enough for casual language use, but since every
                little detail can affect project timelines and investment
                returns business language proficiency among employees is a very
                important consideration for global companies. Some employees
                take business language classes individually, others take
                advantage of company-sponsored corporate language lessons. The
                formats of business language training programs can vary
                significantly: they can be formal (required) or informal
                (suggested); offered on-site or off-site; delivered face-to-face
                or online; designed for groups or conducted individually; rely
                on recorded lessons or live instruction; be available for
                executives or all frontline staff. Popular with individual
                learners and corporate upskilling programs, online business
                language training is one of the most flexible and affordable
                choices among those formats.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title">What is corporate language training? </span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Corporate language training is not just a formalized workforce
                perk, it is often a business necessity for international
                companies. International corporations usually have an official
                corporate language. As they expand operations to countries where
                other native languages are spoken, a business level knowledge in
                a corporate language becomes a necessity. To meet this need,
                human resource departments develop and implement formal
                corporate language training programs. These enable enterprises
                to support international growth, open new markets, share
                knowledge rapidly and more effectively. Business language
                training programs are a win-win both for employees and
                international companies: corporations become more appealing
                places to work for the global talent pool, while employees gain
                access to new opportunities and become more loyal.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title">What is Onlineogrenim Enterprise?</span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Onlineogrenim is a service for online learning that helps
                students find tutors from any place in the world and learn
                languages online. Onlineogrenim Enterprise is a language
                training program specially designed for teams, businesses or
                organizations and focuses on corporate language training.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title"
                  >What Onlineogrenim Enterprise can provide to the team?
                </span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                The teams and businesses that are working with Onlineogrenim
                Enterprise already value the following five benefits of
                corporate language training program: Absolute flexibility that
                employees obtain while improving their business language skills
                without being constrained in terms of time or location from
                anywhere and at anytime An extended list of hand-picked tutors
                that communicate in your team’s native language and can,
                therefore, provide personalized training even for absolute
                beginners Continuous progress tracking using a dashboard
                especially designed to evaluate and track the team’s progress,
                preferred by businesses with multiple locations or HQs in
                different countries Continuous support offered by a designated
                customer support team that speaks your employees’ language and
                can answer any question or solve an issue instantly A one-time
                payment that enables an easy and flexible payment process
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title"
                  >What is the difference between Onlineogrenim and
                  Onlineogrenim сorporate language training?</span
                >
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Onlineogrenim Enterprise is a language training program that
                provides a tailored experience for teams or businesses who are
                able to improve their business language skills on one platform
                with top Onlineogrenim tutors handpicked by the account
                management team. Additionally, Onlineogrenim Enterprise offers
                an easy and systematized administration, analysis, and payment
                for an organization or a business, its students and
                administrators. The organization administrator has access to
                aggregate students’ utilization, learning progress, etc.
                regardless of their geographical location. Lastly, students and
                administrators have access to a professional account management
                team who can help them navigate through the learning experience
                more effectively and efficiently.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title"
                  >What methodology do you use for the сorporate language
                  training?
                </span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Typically, each one of our tutors has their own methodology and
                training resources because we don't believe that one learning
                plan can fit all students' needs. Our experience shows that
                people would like to learn a language in a contextual way (e.g.
                business language), therefore, going through general grammar
                exercises is insufficient and outdated. Our tutors are trained
                to provide a tailored learning plan for each of the student’s
                learning needs such as preparing for a business presentation
                with a native speaker, improving pronunciation, learning
                finance-related business words, or just improving the overall
                language level. Overall, each student will find a tutor that
                works best for their learning needs with our recommendations.
                The student will be provided with a placement test (CEFR levels)
                to understand their initial language level. After 8 classes (or
                on a monthly basis), your team will receive an assessment to
                check the progress they are making towards their learning goal
                and their business language skills.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title"
                  >What is the process of choosing tutors for each employee?
                </span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                One of Onlineogrenim’s main advantages is the opportunity to
                offer a tailored handpick list of top Onlineogrenim tutors
                selected by our account management team, based on your team’s
                learning criteria. Your team will have the opportunity to choose
                their preferred tutor from the handpicked list they will be
                provided with. Our tutors will be notified about the upcoming
                lessons with Enterprise students and will prepare the
                appropriate training materials for the lesson (e.g. business
                language vocabulary and interactive exercises, videos related to
                the business or field your team is working in and other
                contextual resources).
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title"
                  >What are the technical requirements for corporate language
                  training with Onlineogrenim Enterprise?</span
                >
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                A Onlineogrenim Enterprise student has the opportunity to access
                our video platform, Onlineogrenim Space, and get all the
                benefits of an online language training directly on
                Onlineogrenim. All they need is the latest version of Google
                Chrome downloaded on their computers and a pair of headphones to
                start improving their business terminology.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title"
                  >How can I track my team’s progress and utilization of the
                  lessons?
                </span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                We have developed a dashboard to help the management team or the
                administrator track team’s utilization and learning progress.
                The dashboard will help you understand: Overall team’s language
                level (according to CEFR framework) The total number of lessons
                completed, scheduled, funds left, etc. Individual team
                performance and progress Ability to export the data to Microsoft
                Excel
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <span class="title">How does the pricing work?</span>
                <template v-slot:actions>
                  <v-icon color="teal">
                    mdi-check
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                The pricing of each program depends on the number of students,
                tutors’ preferences, the duration of the program. Please request
                a demo to receive all the details about the Enterprise language
                training program.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Item country
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <select name="countryCode" id="">
                  <option data-countryCode="GB" value="44" Selected
                    >UK (+44)</option
                  >
                  <option data-countryCode="US" value="1">USA (+1)</option>
                  <optgroup label="Other countries">
                    <option data-countryCode="DZ" value="213"
                      >Algeria (+213)</option
                    >
                    <option data-countryCode="AD" value="376"
                      >Andorra (+376)</option
                    >
                    <option data-countryCode="AO" value="244"
                      >Angola (+244)</option
                    >
                    <option data-countryCode="AI" value="1264"
                      >Anguilla (+1264)</option
                    >
                    <option data-countryCode="AG" value="1268"
                      >Antigua &amp; Barbuda (+1268)</option
                    >
                    <option data-countryCode="AR" value="54"
                      >Argentina (+54)</option
                    >
                    <option data-countryCode="AM" value="374"
                      >Armenia (+374)</option
                    >
                    <option data-countryCode="AW" value="297"
                      >Aruba (+297)</option
                    >
                    <option data-countryCode="AU" value="61"
                      >Australia (+61)</option
                    >
                    <option data-countryCode="AT" value="43"
                      >Austria (+43)</option
                    >
                    <option data-countryCode="AZ" value="994"
                      >Azerbaijan (+994)</option
                    >
                    <option data-countryCode="BS" value="1242"
                      >Bahamas (+1242)</option
                    >
                    <option data-countryCode="BH" value="973"
                      >Bahrain (+973)</option
                    >
                    <option data-countryCode="BD" value="880"
                      >Bangladesh (+880)</option
                    >
                    <option data-countryCode="BB" value="1246"
                      >Barbados (+1246)</option
                    >
                    <option data-countryCode="BY" value="375"
                      >Belarus (+375)</option
                    >
                    <option data-countryCode="BE" value="32"
                      >Belgium (+32)</option
                    >
                    <option data-countryCode="BZ" value="501"
                      >Belize (+501)</option
                    >
                    <option data-countryCode="BJ" value="229"
                      >Benin (+229)</option
                    >
                    <option data-countryCode="BM" value="1441"
                      >Bermuda (+1441)</option
                    >
                    <option data-countryCode="BT" value="975"
                      >Bhutan (+975)</option
                    >
                    <option data-countryCode="BO" value="591"
                      >Bolivia (+591)</option
                    >
                    <option data-countryCode="BA" value="387"
                      >Bosnia Herzegovina (+387)</option
                    >
                    <option data-countryCode="BW" value="267"
                      >Botswana (+267)</option
                    >
                    <option data-countryCode="BR" value="55"
                      >Brazil (+55)</option
                    >
                    <option data-countryCode="BN" value="673"
                      >Brunei (+673)</option
                    >
                    <option data-countryCode="BG" value="359"
                      >Bulgaria (+359)</option
                    >
                    <option data-countryCode="BF" value="226"
                      >Burkina Faso (+226)</option
                    >
                    <option data-countryCode="BI" value="257"
                      >Burundi (+257)</option
                    >
                    <option data-countryCode="KH" value="855"
                      >Cambodia (+855)</option
                    >
                    <option data-countryCode="CM" value="237"
                      >Cameroon (+237)</option
                    >
                    <option data-countryCode="CA" value="1">Canada (+1)</option>
                    <option data-countryCode="CV" value="238"
                      >Cape Verde Islands (+238)</option
                    >
                    <option data-countryCode="KY" value="1345"
                      >Cayman Islands (+1345)</option
                    >
                    <option data-countryCode="CF" value="236"
                      >Central African Republic (+236)</option
                    >
                    <option data-countryCode="CL" value="56"
                      >Chile (+56)</option
                    >
                    <option data-countryCode="CN" value="86"
                      >China (+86)</option
                    >
                    <option data-countryCode="CO" value="57"
                      >Colombia (+57)</option
                    >
                    <option data-countryCode="KM" value="269"
                      >Comoros (+269)</option
                    >
                    <option data-countryCode="CG" value="242"
                      >Congo (+242)</option
                    >
                    <option data-countryCode="CK" value="682"
                      >Cook Islands (+682)</option
                    >
                    <option data-countryCode="CR" value="506"
                      >Costa Rica (+506)</option
                    >
                    <option data-countryCode="HR" value="385"
                      >Croatia (+385)</option
                    >
                    <option data-countryCode="CU" value="53">Cuba (+53)</option>
                    <option data-countryCode="CY" value="90392"
                      >Cyprus North (+90392)</option
                    >
                    <option data-countryCode="CY" value="357"
                      >Cyprus South (+357)</option
                    >
                    <option data-countryCode="CZ" value="42"
                      >Czech Republic (+42)</option
                    >
                    <option data-countryCode="DK" value="45"
                      >Denmark (+45)</option
                    >
                    <option data-countryCode="DJ" value="253"
                      >Djibouti (+253)</option
                    >
                    <option data-countryCode="DM" value="1809"
                      >Dominica (+1809)</option
                    >
                    <option data-countryCode="DO" value="1809"
                      >Dominican Republic (+1809)</option
                    >
                    <option data-countryCode="EC" value="593"
                      >Ecuador (+593)</option
                    >
                    <option data-countryCode="EG" value="20"
                      >Egypt (+20)</option
                    >
                    <option data-countryCode="SV" value="503"
                      >El Salvador (+503)</option
                    >
                    <option data-countryCode="GQ" value="240"
                      >Equatorial Guinea (+240)</option
                    >
                    <option data-countryCode="ER" value="291"
                      >Eritrea (+291)</option
                    >
                    <option data-countryCode="EE" value="372"
                      >Estonia (+372)</option
                    >
                    <option data-countryCode="ET" value="251"
                      >Ethiopia (+251)</option
                    >
                    <option data-countryCode="FK" value="500"
                      >Falkland Islands (+500)</option
                    >
                    <option data-countryCode="FO" value="298"
                      >Faroe Islands (+298)</option
                    >
                    <option data-countryCode="FJ" value="679"
                      >Fiji (+679)</option
                    >
                    <option data-countryCode="FI" value="358"
                      >Finland (+358)</option
                    >
                    <option data-countryCode="FR" value="33"
                      >France (+33)</option
                    >
                    <option data-countryCode="GF" value="594"
                      >French Guiana (+594)</option
                    >
                    <option data-countryCode="PF" value="689"
                      >French Polynesia (+689)</option
                    >
                    <option data-countryCode="GA" value="241"
                      >Gabon (+241)</option
                    >
                    <option data-countryCode="GM" value="220"
                      >Gambia (+220)</option
                    >
                    <option data-countryCode="GE" value="7880"
                      >Georgia (+7880)</option
                    >
                    <option data-countryCode="DE" value="49"
                      >Germany (+49)</option
                    >
                    <option data-countryCode="GH" value="233"
                      >Ghana (+233)</option
                    >
                    <option data-countryCode="GI" value="350"
                      >Gibraltar (+350)</option
                    >
                    <option data-countryCode="GR" value="30"
                      >Greece (+30)</option
                    >
                    <option data-countryCode="GL" value="299"
                      >Greenland (+299)</option
                    >
                    <option data-countryCode="GD" value="1473"
                      >Grenada (+1473)</option
                    >
                    <option data-countryCode="GP" value="590"
                      >Guadeloupe (+590)</option
                    >
                    <option data-countryCode="GU" value="671"
                      >Guam (+671)</option
                    >
                    <option data-countryCode="GT" value="502"
                      >Guatemala (+502)</option
                    >
                    <option data-countryCode="GN" value="224"
                      >Guinea (+224)</option
                    >
                    <option data-countryCode="GW" value="245"
                      >Guinea - Bissau (+245)</option
                    >
                    <option data-countryCode="GY" value="592"
                      >Guyana (+592)</option
                    >
                    <option data-countryCode="HT" value="509"
                      >Haiti (+509)</option
                    >
                    <option data-countryCode="HN" value="504"
                      >Honduras (+504)</option
                    >
                    <option data-countryCode="HK" value="852"
                      >Hong Kong (+852)</option
                    >
                    <option data-countryCode="HU" value="36"
                      >Hungary (+36)</option
                    >
                    <option data-countryCode="IS" value="354"
                      >Iceland (+354)</option
                    >
                    <option data-countryCode="IN" value="91"
                      >India (+91)</option
                    >
                    <option data-countryCode="ID" value="62"
                      >Indonesia (+62)</option
                    >
                    <option data-countryCode="IR" value="98">Iran (+98)</option>
                    <option data-countryCode="IQ" value="964"
                      >Iraq (+964)</option
                    >
                    <option data-countryCode="IE" value="353"
                      >Ireland (+353)</option
                    >
                    <option data-countryCode="IL" value="972"
                      >Israel (+972)</option
                    >
                    <option data-countryCode="IT" value="39"
                      >Italy (+39)</option
                    >
                    <option data-countryCode="JM" value="1876"
                      >Jamaica (+1876)</option
                    >
                    <option data-countryCode="JP" value="81"
                      >Japan (+81)</option
                    >
                    <option data-countryCode="JO" value="962"
                      >Jordan (+962)</option
                    >
                    <option data-countryCode="KZ" value="7"
                      >Kazakhstan (+7)</option
                    >
                    <option data-countryCode="KE" value="254"
                      >Kenya (+254)</option
                    >
                    <option data-countryCode="KI" value="686"
                      >Kiribati (+686)</option
                    >
                    <option data-countryCode="KP" value="850"
                      >Korea North (+850)</option
                    >
                    <option data-countryCode="KR" value="82"
                      >Korea South (+82)</option
                    >
                    <option data-countryCode="KW" value="965"
                      >Kuwait (+965)</option
                    >
                    <option data-countryCode="KG" value="996"
                      >Kyrgyzstan (+996)</option
                    >
                    <option data-countryCode="LA" value="856"
                      >Laos (+856)</option
                    >
                    <option data-countryCode="LV" value="371"
                      >Latvia (+371)</option
                    >
                    <option data-countryCode="LB" value="961"
                      >Lebanon (+961)</option
                    >
                    <option data-countryCode="LS" value="266"
                      >Lesotho (+266)</option
                    >
                    <option data-countryCode="LR" value="231"
                      >Liberia (+231)</option
                    >
                    <option data-countryCode="LY" value="218"
                      >Libya (+218)</option
                    >
                    <option data-countryCode="LI" value="417"
                      >Liechtenstein (+417)</option
                    >
                    <option data-countryCode="LT" value="370"
                      >Lithuania (+370)</option
                    >
                    <option data-countryCode="LU" value="352"
                      >Luxembourg (+352)</option
                    >
                    <option data-countryCode="MO" value="853"
                      >Macao (+853)</option
                    >
                    <option data-countryCode="MK" value="389"
                      >Macedonia (+389)</option
                    >
                    <option data-countryCode="MG" value="261"
                      >Madagascar (+261)</option
                    >
                    <option data-countryCode="MW" value="265"
                      >Malawi (+265)</option
                    >
                    <option data-countryCode="MY" value="60"
                      >Malaysia (+60)</option
                    >
                    <option data-countryCode="MV" value="960"
                      >Maldives (+960)</option
                    >
                    <option data-countryCode="ML" value="223"
                      >Mali (+223)</option
                    >
                    <option data-countryCode="MT" value="356"
                      >Malta (+356)</option
                    >
                    <option data-countryCode="MH" value="692"
                      >Marshall Islands (+692)</option
                    >
                    <option data-countryCode="MQ" value="596"
                      >Martinique (+596)</option
                    >
                    <option data-countryCode="MR" value="222"
                      >Mauritania (+222)</option
                    >
                    <option data-countryCode="YT" value="269"
                      >Mayotte (+269)</option
                    >
                    <option data-countryCode="MX" value="52"
                      >Mexico (+52)</option
                    >
                    <option data-countryCode="FM" value="691"
                      >Micronesia (+691)</option
                    >
                    <option data-countryCode="MD" value="373"
                      >Moldova (+373)</option
                    >
                    <option data-countryCode="MC" value="377"
                      >Monaco (+377)</option
                    >
                    <option data-countryCode="MN" value="976"
                      >Mongolia (+976)</option
                    >
                    <option data-countryCode="MS" value="1664"
                      >Montserrat (+1664)</option
                    >
                    <option data-countryCode="MA" value="212"
                      >Morocco (+212)</option
                    >
                    <option data-countryCode="MZ" value="258"
                      >Mozambique (+258)</option
                    >
                    <option data-countryCode="MN" value="95"
                      >Myanmar (+95)</option
                    >
                    <option data-countryCode="NA" value="264"
                      >Namibia (+264)</option
                    >
                    <option data-countryCode="NR" value="674"
                      >Nauru (+674)</option
                    >
                    <option data-countryCode="NP" value="977"
                      >Nepal (+977)</option
                    >
                    <option data-countryCode="NL" value="31"
                      >Netherlands (+31)</option
                    >
                    <option data-countryCode="NC" value="687"
                      >New Caledonia (+687)</option
                    >
                    <option data-countryCode="NZ" value="64"
                      >New Zealand (+64)</option
                    >
                    <option data-countryCode="NI" value="505"
                      >Nicaragua (+505)</option
                    >
                    <option data-countryCode="NE" value="227"
                      >Niger (+227)</option
                    >
                    <option data-countryCode="NG" value="234"
                      >Nigeria (+234)</option
                    >
                    <option data-countryCode="NU" value="683"
                      >Niue (+683)</option
                    >
                    <option data-countryCode="NF" value="672"
                      >Norfolk Islands (+672)</option
                    >
                    <option data-countryCode="NP" value="670"
                      >Northern Marianas (+670)</option
                    >
                    <option data-countryCode="NO" value="47"
                      >Norway (+47)</option
                    >
                    <option data-countryCode="OM" value="968"
                      >Oman (+968)</option
                    >
                    <option data-countryCode="PW" value="680"
                      >Palau (+680)</option
                    >
                    <option data-countryCode="PA" value="507"
                      >Panama (+507)</option
                    >
                    <option data-countryCode="PG" value="675"
                      >Papua New Guinea (+675)</option
                    >
                    <option data-countryCode="PY" value="595"
                      >Paraguay (+595)</option
                    >
                    <option data-countryCode="PE" value="51">Peru (+51)</option>
                    <option data-countryCode="PH" value="63"
                      >Philippines (+63)</option
                    >
                    <option data-countryCode="PL" value="48"
                      >Poland (+48)</option
                    >
                    <option data-countryCode="PT" value="351"
                      >Portugal (+351)</option
                    >
                    <option data-countryCode="PR" value="1787"
                      >Puerto Rico (+1787)</option
                    >
                    <option data-countryCode="QA" value="974"
                      >Qatar (+974)</option
                    >
                    <option data-countryCode="RE" value="262"
                      >Reunion (+262)</option
                    >
                    <option data-countryCode="RO" value="40"
                      >Romania (+40)</option
                    >
                    <option data-countryCode="RU" value="7">Russia (+7)</option>
                    <option data-countryCode="RW" value="250"
                      >Rwanda (+250)</option
                    >
                    <option data-countryCode="SM" value="378"
                      >San Marino (+378)</option
                    >
                    <option data-countryCode="ST" value="239"
                      >Sao Tome &amp; Principe (+239)</option
                    >
                    <option data-countryCode="SA" value="966"
                      >Saudi Arabia (+966)</option
                    >
                    <option data-countryCode="SN" value="221"
                      >Senegal (+221)</option
                    >
                    <option data-countryCode="CS" value="381"
                      >Serbia (+381)</option
                    >
                    <option data-countryCode="SC" value="248"
                      >Seychelles (+248)</option
                    >
                    <option data-countryCode="SL" value="232"
                      >Sierra Leone (+232)</option
                    >
                    <option data-countryCode="SG" value="65"
                      >Singapore (+65)</option
                    >
                    <option data-countryCode="SK" value="421"
                      >Slovak Republic (+421)</option
                    >
                    <option data-countryCode="SI" value="386"
                      >Slovenia (+386)</option
                    >
                    <option data-countryCode="SB" value="677"
                      >Solomon Islands (+677)</option
                    >
                    <option data-countryCode="SO" value="252"
                      >Somalia (+252)</option
                    >
                    <option data-countryCode="ZA" value="27"
                      >South Africa (+27)</option
                    >
                    <option data-countryCode="ES" value="34"
                      >Spain (+34)</option
                    >
                    <option data-countryCode="LK" value="94"
                      >Sri Lanka (+94)</option
                    >
                    <option data-countryCode="SH" value="290"
                      >St. Helena (+290)</option
                    >
                    <option data-countryCode="KN" value="1869"
                      >St. Kitts (+1869)</option
                    >
                    <option data-countryCode="SC" value="1758"
                      >St. Lucia (+1758)</option
                    >
                    <option data-countryCode="SD" value="249"
                      >Sudan (+249)</option
                    >
                    <option data-countryCode="SR" value="597"
                      >Suriname (+597)</option
                    >
                    <option data-countryCode="SZ" value="268"
                      >Swaziland (+268)</option
                    >
                    <option data-countryCode="SE" value="46"
                      >Sweden (+46)</option
                    >
                    <option data-countryCode="CH" value="41"
                      >Switzerland (+41)</option
                    >
                    <option data-countryCode="SI" value="963"
                      >Syria (+963)</option
                    >
                    <option data-countryCode="TW" value="886"
                      >Taiwan (+886)</option
                    >
                    <option data-countryCode="TJ" value="7"
                      >Tajikstan (+7)</option
                    >
                    <option data-countryCode="TH" value="66"
                      >Thailand (+66)</option
                    >
                    <option data-countryCode="TG" value="228"
                      >Togo (+228)</option
                    >
                    <option data-countryCode="TO" value="676"
                      >Tonga (+676)</option
                    >
                    <option data-countryCode="TT" value="1868"
                      >Trinidad &amp; Tobago (+1868)</option
                    >
                    <option data-countryCode="TN" value="216"
                      >Tunisia (+216)</option
                    >
                    <option data-countryCode="TR" value="90"
                      >Turkey (+90)</option
                    >
                    <option data-countryCode="TM" value="7"
                      >Turkmenistan (+7)</option
                    >
                    <option data-countryCode="TM" value="993"
                      >Turkmenistan (+993)</option
                    >
                    <option data-countryCode="TC" value="1649"
                      >Turks &amp; Caicos Islands (+1649)</option
                    >
                    <option data-countryCode="TV" value="688"
                      >Tuvalu (+688)</option
                    >
                    <option data-countryCode="UG" value="256"
                      >Uganda (+256)</option
                    >
                    <!-- <option data-countryCode="GB" value="44">UK (+44)</option> -->
                    <option data-countryCode="UA" value="380"
                      >Ukraine (+380)</option
                    >
                    <option data-countryCode="AE" value="971"
                      >United Arab Emirates (+971)</option
                    >
                    <option data-countryCode="UY" value="598"
                      >Uruguay (+598)</option
                    >
                    <!-- <option data-countryCode="US" value="1">USA (+1)</option> -->
                    <option data-countryCode="UZ" value="7"
                      >Uzbekistan (+7)</option
                    >
                    <option data-countryCode="VU" value="678"
                      >Vanuatu (+678)</option
                    >
                    <option data-countryCode="VA" value="379"
                      >Vatican City (+379)</option
                    >
                    <option data-countryCode="VE" value="58"
                      >Venezuela (+58)</option
                    >
                    <option data-countryCode="VN" value="84"
                      >Vietnam (+84)</option
                    >
                    <option data-countryCode="VG" value="84"
                      >Virgin Islands - British (+1284)</option
                    >
                    <option data-countryCode="VI" value="84"
                      >Virgin Islands - US (+1340)</option
                    >
                    <option data-countryCode="WF" value="681"
                      >Wallis &amp; Futuna (+681)</option
                    >
                    <option data-countryCode="YE" value="969"
                      >Yemen (North)(+969)</option
                    >
                    <option data-countryCode="YE" value="967"
                      >Yemen (South)(+967)</option
                    >
                    <option data-countryCode="ZM" value="260"
                      >Zambia (+260)</option
                    >
                    <option data-countryCode="ZW" value="263"
                      >Zimbabwe (+263)</option
                    >
                  </optgroup>
                </select>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row></v-container
  >
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          header: "Today"
        },
        { divider: true },
        {
          avatar: "https://picsum.photos/250/300?image=660",
          title: "Meeting @ Noon",
          subtitle: `<span class="font-weight-bold">Spike Lee</span> &mdash; I'll be in your neighborhood`
        },
        {
          avatar: "https://picsum.photos/250/300?image=821",
          title: 'Summer BBQ <span class="grey--text text--lighten-1"></span>',
          subtitle:
            '<span class="font-weight-bold">to Operations support</span> &mdash; Wish I could come.'
        },
        {
          avatar: "https://picsum.photos/250/300?image=783",
          title: "Yes yes",
          subtitle:
            '<span class="font-weight-bold">Bella</span> &mdash; Do you have Paris recommendations'
        },
        {
          header: "Yesterday"
        },
        { divider: true },
        {
          avatar: "https://picsum.photos/250/300?image=1006",
          title: "Dinner tonight?",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?'
        },
        {
          avatar: "https://picsum.photos/250/300?image=146",
          title: "So long",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?'
        },
        {
          header: "Last Week"
        },
        { divider: true },
        {
          avatar: "https://picsum.photos/250/300?image=1008",
          title: "Breakfast?",
          subtitle:
            '<span class="font-weight-bold">LaToya</span> &mdash; Do you want to hang out?'
        },
        {
          avatar: "https://picsum.photos/250/300?image=839",
          title:
            'Winter Porridge <span class="grey--text text--lighten-1"></span>',
          subtitle:
            '<span class="font-weight-bold">cc: Daniel</span> &mdash; Tell me more...'
        },
        {
          avatar: "https://picsum.photos/250/300?image=145",
          title: "Oui oui",
          subtitle:
            '<span class="font-weight-bold">Nancy</span> &mdash; Do you see what time it is?'
        }
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style>
.header {
  background-image: url("https://th.bing.com/th/id/OIP.eKV9gPC6IR262C0_kqO4WgHaGW?pid=ImgDet&rs=1");
}
</style>
